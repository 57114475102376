import { useEffect, useState } from "react";
import Footer from "../components/Footer";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import AnimatedPage from "../components/AnimatedPage";

function Notification() {
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    const [isShow, setShow] = useState(false);
    const [notify, setNotify] = useState(JSON.parse(localStorage.getItem("notify")));
    const [notifyItem, setNotifyItem] = useState();
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate(-1);
    };
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then((res) => {
                if (profile != res.data.data) {
                    setProfile(res.data.data);
                    localStorage.setItem("profile", JSON.stringify(res.data.data));
                }
            })
            .catch((err) => localStorage.removeItem("user"));

        axios.get(`${process.env.REACT_APP_API_URL}/auth/getnotify`, {}).then((res) => {
            if (notify != res.data.data) {
                setNotify(res.data.data);
                localStorage.setItem("notify", JSON.stringify(res.data.data));
            }
        });
    }, []);

    return (
        <>
            <div className="title-top">
                <div className="back" onClick={handleBackClick}>
                    <KeyboardArrowLeftOutlinedIcon />
                </div>
                <h1 className="title-h1">Thông báo</h1>
            </div>
            <div className="main">
                <div style={{ position: "relative", margin: "0.3rem 0 0" }}>
                    {/*<div className="box-image">
						<img alt="" src={require("../../img/tb1.jpg")} />
						<div className="box-image-title">Thông báo 1</div>
					</div>
					<div className="box-image">
						<img alt="" src={require("../../img/tb2.jpg")} />
						<div className="box-image-title">Thông báo 2</div>
					</div>
					<div className="box-image">
						<img alt="" src={require("../../img/tb3.jpg")} />
						<div className="box-image-title">Thông báo 3</div>
					</div>*/}
                    {notify != null ? (
                        <>
                            <AnimatedPage>
                                {notify.map((item, index) => (
                                    <>
                                        {index > 1 && item.title !== "marquee" && item.title !== "popup" && item.isShow === true ? (
                                            <div
                                                className="box-image"
                                                onClick={() => {
                                                    if (item.title.indexOf("{") !== 0) {
                                                        setNotifyItem(item);
                                                        setShow(true);
                                                    } else {
                                                        navigate(item.content.replace(/<\/?[^>]+(>|$)/g, ""));
                                                    }
                                                }}>
                                                <img alt="" src={item.image} style={{ width: "100%" }} />
                                                <div className="box-image-title">{item.title.replace("}", "").replace("{", "")}</div>
                                            </div>
                                        ) : null}
                                    </>
                                ))}
                            </AnimatedPage>
                        </>
                    ) : (
                        <div
                            style={{
                                fontSize: "16px",
                                textAlign: "center",
                                padding: "10px",
                                color: "#fff"
                            }}>
                            Đang cập nhật dữ liệu
                        </div>
                    )}
                </div>
                {isShow === true ? (
                    <>
                        {notifyItem != null ? (
                            <div className="popup-backdrop">
                                <div className="popup-main">
                                    <div className="popup-header">{notifyItem.title}</div>
                                    <div className="popup-content">
                                        {notifyItem.content.indexOf("{") != -1 && notifyItem.content.indexOf("{") != -1 ? (
                                            <>
                                                <img
                                                    src={notifyItem.content.substring(notifyItem.content.indexOf("{") + 1, notifyItem.content.indexOf("}"))}
                                                    style={{ width: "100%", height: "auto" }}
                                                />
                                                <div
                                                    dangerouslySetInnerHTML={{
                                                        __html: notifyItem.content.replace(
                                                            notifyItem.content.substring(notifyItem.content.indexOf("{"), notifyItem.content.indexOf("}") + 1),
                                                            ""
                                                        )
                                                    }}
                                                />
                                            </>
                                        ) : (
                                            <div
                                                dangerouslySetInnerHTML={{
                                                    __html: notifyItem.content
                                                }}
                                            />
                                        )}
                                    </div>
                                    <button onClick={() => setShow(false)} className="popup-close">
                                        Đóng
                                    </button>
                                </div>
                            </div>
                        ) : null}
                    </>
                ) : null}
            </div>
            <Footer />
        </>
    );
}

export default Notification;
