import Footer from "../components/Footer";
import { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import KeyboardArrowLeftOutlinedIcon from "@mui/icons-material/KeyboardArrowLeftOutlined";
import AnimatedPage from "../components/AnimatedPage";
import swal from "sweetalert";
import Header from "../components/Header";

function Sports() {
    const [saba, setSaba] = useState("https://t5x4gr.pcy5720.net/vi-VN/live/1");
    const [isLoading, setLoading] = useState(true);
    const [profile, setProfile] = useState(JSON.parse(localStorage.getItem("profile")));
    const navigate = useNavigate();
    const handleBackClick = () => {
        navigate("/");
    };
    axios.interceptors.request.use(
        (config) => {
            const token = localStorage.getItem("user");
            if (token) {
                config.headers["Authorization"] = `Bearer ${token}`;
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    );
    useEffect(() => {
        axios
            .get(`${process.env.REACT_APP_API_URL}/auth/getUser`, {})
            .then(() => {
                const form = {
                    product_type: "142",
                    game_code: "FB0001",
                    platform: "html5"
                };
                axios
                    .post(`${process.env.REACT_APP_API_URL}/casino/launchgame`, form)
                    .then((res) => {
                        if (res.data.data.status == 0) {
                            setLoading(false);
                            setSaba(res.data.data.game_url);
                        } else {
                            setLoading(false);
                            swal("Sảnh đang bảo trì", "", "error").then(() => navigate("/"));
                        }
                    })
                    .catch((err) => {
                        setLoading(false);
                        swal("Sảnh đang bảo trì", "", "error").then(() => navigate("/"));
                    });
            })
            .catch((err) => {
                setLoading(false);
            });
    }, []);
    return (
        <>
            {isLoading ? (
                <div className="loading">
                    <div className="loader"></div>
                </div>
            ) : null}
            <Header profile={profile} />
            <div style={{ padding: "0 0 2rem" }}>
                <AnimatedPage>
                    <iframe
                        src={saba}
                        style={{
                            position: "fixed",
                            top: "50px",
                            width: "100%",
                            maxWidth: "450px",
                            left: "50%",
                            transform: "translateX(-50%)",
                            height: "calc(var(--viewport-height) - 100px)"
                        }}></iframe>
                </AnimatedPage>
            </div>
            <Footer />
        </>
    );
}
export default Sports;
