import "./footer.css";
import { Link, useLocation } from "react-router-dom";

function Footer(props) {
    var profile = props.profile;
    const location = useLocation();
    const data = [
        {
            icon: "home",
            iconactive: "home-active",
            title: "Trang Chủ",
            to: "/"
        },
        {
            icon: "sport",
            iconactive: "sport-active",
            title: "Thể Thao",
            to: "/sports"
        },
        {
            icon: "at",
            iconactive: "at-active",
            title: "Ưu Đãi",
            to: "/notification"
        },
        {
            icon: "mine",
            iconactive: "mine-active",
            title: "Cá Nhân",
            to: "/profile"
        },
        {
            icon: "bs",
            iconactive: "bs-active",
            title: "Nạp Tiền",
            to: "/recharge"
        },
        {
            icon: "ec",
            iconactive: "ec-active",
            title: "Hỗ Trợ",
            to: "/service"
        }
    ];

    return (
        <>
            <div className="footer">
                {data.map((item, index) => (
                    <div className={location.pathname === item.to || location.pathname.substring(0, 9) === item.to ? "footer_item active" : "footer_item"} key={index}>
                        <Link style={{ textDecoration: "none" }} to={item.to}>
                            <div className="icon_footer">
                                {location.pathname === item.to || location.pathname.substring(0, 9) === item.to ? (
                                    <img alt="" src={require(`../../static/${item.iconactive}.png`)} />
                                ) : (
                                    <img alt="" src={require(`../../static/${item.icon}.png`)} />
                                )}
                            </div>
                            <div className="title_footer">{item.title}</div>
                        </Link>
                    </div>
                ))}
            </div>
        </>
    );
}
export default Footer;
