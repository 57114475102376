import React from "react";
import { Link, useNavigate } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsIcon from "@mui/icons-material/Notifications";

const Header = ({ profile }) => {
    const navigate = useNavigate();
    return (
        <div className="header">
            <div className="logo">
                <Link to="/">
                    <img alt="" src="/images/logo.png" />
                </Link>
            </div>
            <div className="header-right" onClick={() => navigate("/profile")}>
                {profile ? (
                    <>
                        <div className="header-profile" style={{ flexDirection: "column", gap: "0" }}>
                            {/*<div style={{ color: "#777" }}>{profile?.username}</div>*/}
                            <div style={{ color: "#577788", fontSize: "12px", lineHeight: "1" }}>VND</div>
                            <div style={{ fontWeight: "bold" }}>{Math.floor(profile?.money).toLocaleString("vi-VN")}</div>
                        </div>
                        <div style={{ display: "flex", marginLeft: "8px" }}>
                            <img src={require("../../static/deposit-btn.png")} style={{ height: "30px" }} />
                        </div>
                    </>
                ) : (
                    <>
                        <Link to="/login" className="btn-login" style={{ marginBottom: "0" }}>
                            Đăng nhập
                        </Link>
                        <Link to="/register" className="btn-register" style={{ marginBottom: "0" }}>
                            Đăng ký
                        </Link>
                    </>
                )}
            </div>
        </div>
    );
};

export default Header;
